<template>
  <div>
    <el-form :model="form"
             label-width="80px">
      <el-row :gutter="10"
              style="margin:10px 0">
        <el-col :span="12">
          <el-button type="primary"
                     @click="addTeacher">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <EditForm ref="EditFormRef" />
    <AddStu ref="AddStuRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import EditForm from './components/editForm.vue'
import AddStu from './components/checkStudent.vue'
export default {
  data () {
    return {
      form: {
        room_name: '',
      },
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_classes_class_teacher_admin',
      tableColumns: [
        { prop: 'id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'grade_name', align: 'center', label: '年级', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'mode_name', align: 'center', label: '模式', width: '', showToopic: false },
        { prop: 'teacher_name', align: 'center', label: '教师', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '添加学生', type: 'student', event: 'addStu' },
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      schoolList: [],
      gradeList: [],
    }
  },
  components: {
    Form, EditForm, AddStu
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '开启' : '关闭'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = {
        special_class_id: this.$route.query.special_class_id
      }
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/special/class_subject_lst',
        method: 'get',
        params: form
      }).then(res => {
        for (let index = 0; index < res.data.list.length; index++) {
          const element = res.data.list[index];
          var str = ''
          element.teacher.forEach(item => {
            if (str) {
              str += '，'
            }
            str += `${item.edu_user_name}`
          });
          element.teacher_name = str

        }
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/special/class_subject_del',
          method: 'post',
          data: {
            class_subject_id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addTeacher () {
      let form = {
        special_class_id: this.$route.query.special_class_id,
        grade_id: '',
        subject_id: '',
        mode_id: '',
        teachers: [],
      }
      this.$refs.FormRef.text = '添加'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    addStu (row) {
      let form = {
        graduated_id: '',
        class_id: '',
        truename: '',
        special_class_id: this.$route.query.special_class_id
      }
      this.$refs.AddStuRef.form = form
      this.$refs.AddStuRef.special_class_id = row.id
      this.$refs.AddStuRef.arr = row.student
      this.$refs.AddStuRef.getList(row.special_class_id)
      this.$refs.AddStuRef.dialogVisible = true
    },
    editRow (row) {
      var teachers = row.teacher.map(item => item.edu_user_id)
      let form = {
        special_class_id: row.special_class_id,
        grade_id: row.grade_id,
        subject_id: row.subject_id,
        mode_id: row.mode_id,
        teachers,
        id: row.id
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getMode()

      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.dialogVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
